<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <a-drawer width="720px" :mask="false" v-model:open="open" class="backlog_center_drawer" placement="right">
        <template #title>
            <div class="drawer_title">
                <span>待办中心</span>
            </div>
        </template>
        <div class="tabs_box">
            <a-tabs v-model:activeKey="activeKey" @change="onChangeTabs">
                <a-tab-pane key="1">
                    <template #tab>
                        <div class="tab_pane_row">
                            <span>待我签署</span>
                            <span class="a_link">({{ dataSource.length }})</span>
                        </div>
                    </template>
                </a-tab-pane>
                <!-- <a-tab-pane key="2">
                    <template #tab>
                        <div class="tab_pane_row">
                            <span>待我审批</span>
                            <span class="a_link">(9)</span>
                        </div>
                    </template>
                </a-tab-pane> -->
            </a-tabs>
        </div>
        <div class="table_box">
            <a-table :columns="columns" :dataSource="dataSource" :pagination="{
                total: dataSource.length
            }">
                <template #bodyCell="{ column, text, record }">
                    <template v-if="column.key == 'name'">
                        <div class="contract_name ellipsis_multiline" v-if="activeKey == 1">
                            <a-tooltip :title="text">
                                <span>{{ text }}</span>
                            </a-tooltip>
                        </div>
                        <div class="contract_name_info" v-else>
                            <div class="name">
                                <span>{{ record.gsName }}</span>
                            </div>
                            <div class="initiator_row">
                                <span class="label">发起方：</span>
                                <span class="value">{{ record.sourceName }}</span>
                            </div>
                        </div>
                    </template>
                    <template v-if="column.key == 'signatory'">
                        <div class="signatory_info">
                            <div class="initiator_row">
                                <span class="label">发起方：</span>
                                <span class="value">{{ record.sourceName }}</span>
                            </div>
                            <div class="corporation_row">
                                <span>{{ record.gsName }}</span>
                            </div>
                        </div>
                    </template>
                    <template v-if="column.key == 'operation'">
                        <a-button disabled type="link" v-if="activeKey == 1" >签署</a-button>
                        <a-button type="link" v-else>同意</a-button>
                    </template>
                </template>
            </a-table>
        </div>
    </a-drawer>
</template>
<script>
import { onMounted, ref, watch } from 'vue';
import { columns_1, columns_2 } from './constance';
import { getDocSearch } from '@/request/https/components';
import { message } from 'ant-design-vue';

export default {
    setup() {
        const open = ref(false);
        const dataSource = ref([]);
        const columns = ref(columns_1);
        const paramData = ref({
            docName: null,
            docType: null,
            pageIndex: 1,
            pageSize: 100,
            status: 1
        });
        const activeKey = ref(1);

        /**
         * @function 切换
         * @param v 
         */
        const onChangeTabs = (v) => {
            activeKey.value = v;
            if (v == 1) columns.value = columns_1;
            else columns.value = columns_2;
        };

        /**
         * @function 获取数据
         */
        const getDocSearchData = async () => {
            const res = await getDocSearch(paramData.value);
            if (res.code == 200) {
                dataSource.value = res.data.itemList
            }
        };

        const onSign = () => {
            message.warning('开发中...');
        };

        watch(open, (newVal) => {
           if(newVal) getDocSearchData();
        });

        onMounted(() => {
            columns.value = columns_1;
        });

        return {
            open,
            columns,
            dataSource,
            activeKey,
            onChangeTabs,
            onSign
        }
    }
}
</script>
<style lang="scss">
.backlog_center_drawer {
    .ant-drawer-body {
        padding: 8px 0;
    }

    .tabs_box {
        padding: 0 24px;
        position: relative;
    }

    .table_box {
        padding: 16px 24px;

        .signatory_info {

            .initiator_row,
            .corporation_row {
                line-height: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            .corporation_row {
                color: #646566;
                margin-top: 4px;
            }
        }

        .contract_name_info {

            .initiator_row {
                line-height: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                color: #646566;
                margin-top: 4px;
            }
        }
    }
}
</style>