<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <a-drawer width="560px" :mask="false" v-model:open="open" class="message_center_drawer" placement="right">
        <template #title>
            <div class="drawer_title">
                <span>消息中心</span>
            </div>
        </template>
        <div class="tabs_box">
            <a-tabs v-model:activeKey="activeKey">
                <a-tab-pane key="1">
                    <template #tab>
                        <div class="tab_pane_row">
                            <span>文件消息</span>
                            <!-- <div class="msg_num" style="right: -28px">
                                <span>99+</span>
                            </div>   -->
                        </div>
                    </template>
                </a-tab-pane>
                <a-tab-pane key="2">
                    <template #tab>
                        <div class="tab_pane_row">
                            <span>审批消息</span>
                            <div class="msg_num">
                                <span>9</span>
                            </div>
                        </div>
                    </template>
                </a-tab-pane>
                <a-tab-pane key="3">
                    <template #tab>
                        <div class="tab_pane_row">
                            <span>系统消息</span>
                            <div class="msg_num" style="right: -20px">
                                <span>79</span>
                            </div>
                        </div>
                    </template>
                </a-tab-pane>
            </a-tabs>
            <div class="markers_icon">
                <a-tooltip title="全部标记已读" placement="bottomRight">
                    <img src="../../assets/layouts/bjyd_icon.png" alt="" class="icon icon_def" />
                    <img src="../../assets/layouts/bjyd_sel_icon.png" alt="" class="icon icon_sel" />
                </a-tooltip>
            </div>
        </div>
        <div class="message_content" v-if="messageList.length > 0">
            <div class="message_list" v-for="item in messageList" :key="item.messageId">
                <div class="message_list_left">
                    <div class="title_date">
                        <span class="title">签署通知</span>
                        <span class="date">{{ item.time }}</span>
                    </div>
                    <div class="content_button">
                        <span class="content">
                            {{ item.desc }}
                            <span class="a_link">前往查看</span>
                        </span>
                    </div>
                </div>
                <div class="message_list_right">
                    <div class="mark"></div>
                </div>
            </div>
        </div>
        <div class="message_content" v-else>
            <div class="empty_box">
                <a-empty :image="simpleImage" />
            </div>
        </div>
    </a-drawer>
</template>
<script>
import { onMounted, ref } from 'vue';
import { postMessageList, getReadMessage } from '@/request/https/home';
import { Empty } from 'ant-design-vue';

export default {
    setup() {
        const open = ref(false);
        const activeKey = ref('1');
        const messageList = ref([]);

        const onSetActiveKey = () => {
            console.log()
        };

        /**
         * @function 获取消息列表
         */
        const postMessageListData = async () => {
            const res = await postMessageList({

            });
            if(res == 200) {
                messageList.value = res.data;
            }
        };

        onMounted(() => {
            postMessageListData();
        });

        return {
            open,
            activeKey,
            messageList,
            simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
            onSetActiveKey
        }
    }
}
</script>
<style lang="scss">
.message_center_drawer {
    .ant-drawer-body {
        padding: 8px 0;
    }

    .tabs_box {
        padding: 0 24px;
        position: relative;

        .markers_icon {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 12px;
            right: 16px;
            z-index: 10;

            .icon {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }

            .icon_def {
                display: block;
            }

            .icon_sel {
                display: none;
            }
        }

        .markers_icon:hover {
            .icon_def {
                display: none;
            }

            .icon_sel {
                display: block;
            }
        }

        .tab_pane_row {
            position: relative;

            .msg_num {
                padding: 0 5px;
                height: 18px;
                font-size: 12px;
                color: #fff;
                background-color: #F5222D;
                border-radius: 18px;
                position: absolute;
                top: -10px;
                right: -16px;
                z-index: 10;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .message_content {
        height: calc(100vh - 136px);
        padding: 0 24px;
        margin-top: 16px;
        overflow: auto;

        .message_list {
            padding: 16px 0;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            border-bottom: 1px solid #EBEDF0;

            .message_list_left {
                flex: 1;

                .title_date,
                .content_button {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }

                .title_date {
                    min-height: 18px;

                    .title {
                        font-weight: 500;
                        font-size: 14px;
                        color: #323233;
                    }

                    .date {
                        font-size: 14px;
                        color: #969799;
                        margin-left: 16px;
                    }
                }

                .content_button {
                    font-size: 14px;
                    color: #646566;
                    line-height: 22px;
                    margin-top: 8px;
                }
            }

            .message_list_right {
                height: 18px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-left: 12px;

                .mark {
                    width: 8px;
                    height: 8px;
                    background: #F5222D;
                    border-radius: 8px;
                }
            }
        }

        .empty_box {
            padding: 50px 0;
        }
    }
}
</style>