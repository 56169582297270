import request from "@/request/index";

/**
 * @function 待办列表（文档数据统计）
 * @param param 
 * @returns 
 */
export const getDocStatistics = (param) => {
    return new Promise((resolve, reject) => {
        request({
            url: '/seal/signFile/doc-statistics',
            method: 'post',
            data: param,
            headers: {

            }
        }).then((res) => {
            return resolve(res);
        }).catch((err) => {
            return reject(err);
        });
    })
};

/**
 * @function 消息列表
 */		
export const postMessageList = (param) => {
    return new Promise((resolve, reject) => {
        request({
            url: '/message/message-list',
            method: 'post',
            data: param,
            headers: {

            }
        }).then((res) => {
            return resolve(res);
        }).catch((err) => {
            return reject(err);
        });
    })
};

/**
 * @function 已读消息
 */		
export const getReadMessage = (param) => {
    return new Promise((resolve, reject) => {
        request({
            url: '/message/read-message',
            method: 'get',
            params: param,
            headers: {

            }
        }).then((res) => {
            return resolve(res);
        }).catch((err) => {
            return reject(err);
        });
    })
};

/**
 * @function 用户权益列表
 */		
export const getUserEquityList = (param) => {
    return new Promise((resolve, reject) => {
        request({
            url: '/v1/user_seal_equity/user-equity-list',
            method: 'get',
            params: param,
            headers: {

            }
        }).then((res) => {
            return resolve(res);
        }).catch((err) => {
            return reject(err);
        });
    })
};

/**
 * @function 名片数据统计
 */		
export const postDataStatistics = (param) => {
    return new Promise((resolve, reject) => {
        request({
            url: '/bc/dataStatistics',
            method: 'post',
            data: param,
            headers: {

            }
        }).then((res) => {
            return resolve(res);
        }).catch((err) => {
            return reject(err);
        });
    })
};