import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/login',
        name: 'login_page',
        meta: {
            title: '登陆'
        },
        hiddenMenu: true,
        component: () => import('@/views/login/index.vue')
    },
    {
        path: '/home',
        name: 'home_page',
        meta: {
            title: '首页'
        },
        component: () => import('@/views/home/index.vue')
    },
    // {
    //     path: '/applicationCenter',
    //     name: 'application_center_page',
    //     meta: {
    //         title: '应用中心'
    //     },
    //     component: () => import('@/views/applicationCenter/index.vue')
    // },
    // {
    //     path: '/productArea',
    //     name: 'product_area_page',
    //     meta: {
    //         title: '产品专区'
    //     },
    //     component: () => import('@/views/productArea/index.vue')
    // },
    {
        path: '/aiCallingCard',
        name: 'ai_calling_card_page',
        meta: {
            title: 'ai名片秀'
        },
        hiddenMenu: true,
        component: () => import('@/views/applicationScenario/aiCallingCard.vue')
    },
    {
        path: '/atWillMySign',
        name: 'at_will_my_sign_page',
        meta: {
            title: '任我签'
        },
        hiddenMenu: true,
        component: () => import('@/views/applicationScenario/atWillMySign.vue')
    },
    {
        path: '/globalService',
        name: 'global_service_page',
        meta: {
            title: '全域营销'
        },
        hiddenMenu: true,
        component: () => import('@/views/applicationScenario/globalService.vue')
    },
    {
        path: '/enterpriseCertification',
        name: 'enterprise_certification_page',
        meta: {
            title: '企业认证'
        },
        hiddenMenu: true,
        component: () => import('@/views/enterpriseCertification/index.vue')
    },
    {
        path: '/personalCertification',
        name: 'personal_certification_page',
        meta: {
            title: '个人认证'
        },
        hiddenMenu: true,
        component: () => import('@/views/personalCertification/index.vue')
    },
    {
        path: '/industryCase',
        name: 'industry_caseView_page',
        meta: {
            title: '行业案例'
        },
        hiddenMenu: true,
        component: () => import('@/views/industryCase/index.vue')
    },
    {
        path: '/industryCaseDetails',
        name: 'industryCase_details_page',
        meta: {
            title: '行业案例详情'
        },
        hiddenMenu: true,
        component: () => import('@/views/industryCase/details.vue')
    },
    {
        path: '/userCenter',
        name: 'user_enter',
        meta: {
            title: '用户中心'
        },
        children: [
            {
                path: '/userCenter/infoManage',
                name: 'info_manage_page',
                meta: {
                    title: '信息管理'
                },
                component: () => import('@/views/userCenter/infoManage/index.vue')
            }, {
                path: '/userCenter/infoManage/editLoginPassword',
                name: 'edit_login_password_page',
                meta: {
                    title: '修改登录密码'
                },
                hiddenMenu: true,
                component: () => import('@/views/userCenter/editLoginPassword/index.vue')
            }, {
                path: '/userCenter/infoManage/editSignPassword',
                name: 'edit_sign_password_page',
                meta: {
                    title: '修改签署密码'
                },
                hiddenMenu: true,
                component: () => import('@/views/userCenter/editSignPassword/index.vue')
            }, {
                path: '/userCenter/sealManage',
                name: 'seal_manage_page',
                meta: {
                    title: '印章管理'
                },
                component: () => import('@/views/userCenter/sealManage/index.vue')
            }, {
                path: '/userCenter/sealManage/claimRecord',
                name: 'claim_record_page',
                meta: {
                    title: '申领记录'
                },
                hiddenMenu: true,
                component: () => import('@/views/userCenter/claimRecord/index.vue')
            }, {
                path: '/userCenter/sealManage/applyForSeal',
                name: 'apply_for_seal_page',
                meta: {
                    title: '申领印章'
                },
                hiddenMenu: true,
                component: () => import('@/views/userCenter/applyForSeal/index.vue')
            }, {
                path: '/userCenter/contactPersonManage',
                name: 'contact_person_manage_page',
                meta: {
                    title: '联系人管理'
                },
                component: () => import('@/views/userCenter/contactPersonManage/index.vue')
            }
        ]
    },
    {
        path: '/',
        redirect: '/login'
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;