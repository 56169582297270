
import request from "@/request/index";

/**
 * @function 文档查询列表
 * @param param 
 * @returns 
 */
export const getDocSearch = (param) => {
    return new Promise((resolve, reject) => {
        request({
            url: '/seal/signFile/docSearch',
            method: 'post',
            data: param,
            headers: {

            }
        }).then((res) => {
            return resolve(res);
        }).catch((err) => {
            return reject(err);
        });
    })
};