export const noLayoutsPath = [
	'/login',
    '/globalService',
    '/atWillMySign',
    '/aiCallingCard',
    '/industryCase',
    '/industryCaseDetails'
];

export const menuPath = [
    '/home',
    '/applicationCenter',
    '/productArea',
    '/userCenter',
    '/userCenter/infoManage',
    '/userCenter/sealManage',
    '/userCenter/contactPersonManage'
];

export const menuIcon = {
    '/home': {
        defImg: require('../assets/layouts/gzt_def_icon.png'),
        selImg: require('../assets/layouts/gzt_sel_icon.png')
    },
    '/applicationCenter': {
        defImg: require('../assets/layouts/yyzx_def_icon.png'),
        selImg: require('../assets/layouts/yyzx_sel_icon.png')
    },
    '/productArea': {
        defImg: require('../assets/layouts/cpzq_def_icon.png'),
        selImg: require('../assets/layouts/cpzq_sel_icon.png')
    },
    '/userCenter': {
        defImg: require('../assets/layouts/yhzx_def_icon.png'),
        selImg: require('../assets/layouts/yhzx_sel_icon.png')
    }
};