import request from "@/request/index";

/**
 * @function 登录-根据手机号
 * @param param 
 * @returns 
 */
export const postLogin = (param) => {
    return new Promise((resolve, reject) => {
        request({
            url: '/login/login-by-phone',
            method: 'post',
            data: param,
            headers: {

            }
        }).then((res) => {
            return resolve(res);
        }).catch((err) => {
            return reject(err);
        });
    })
};

/**
 * @function 注册-无主体
 * @param param 
 * @returns 
 */
export const postRegister = (param) => {
    return new Promise((resolve, reject) => {
        request({
            url: '/login/register',
            method: 'post',
            data: param,
            headers: {

            }
        }).then((res) => {
            return resolve(res);
        }).catch((err) => {
            return reject(err);
        });
    })
};

/**
 * @function 获取用户信息
 * @param param 
 * @returns 
 */
export const getUserInfo = (param) => {
    return new Promise((resolve, reject) => {
        request({
            url: '/login/get-user-info',
            method: 'post',
            data: param,
            headers: {

            }
        }).then((res) => {
            return resolve(res);
        }).catch((err) => {
            return reject(err);
        });
    })
};

/**
 * @function 主体列表
 * @param param 
 * @returns 
 */
export const getBodyList = (param) => {
    return new Promise((resolve, reject) => {
        request({
            url: '/user/body-list',
            method: 'post',
            data: param,
            headers: {

            }
        }).then((res) => {
            return resolve(res);
        }).catch((err) => {
            return reject(err);
        });
    })
};

/**
 * @function 切换主体
 * @param param 
 * @returns 
 */
export const postChangeBody = (param) => {
    return new Promise((resolve, reject) => {
        request({
            url: '/user/change-body',
            method: 'post',
            data: param,
            headers: {

            }
        }).then((res) => {
            return resolve(res);
        }).catch((err) => {
            return reject(err);
        });
    })
};

/**
 * @function 发送验证码
 * @param param 
 * @returns 
 * 1.登录 2.修改密码 3.修改手机号 4.申领单位电子印章 5.申领个人电子印章 10.修改签署密码
 */
export const postVerificationCode = (param) => {
    return new Promise((resolve, reject) => {
        request({
            url: '/login/send-verification-code',
            method: 'post',
            data: param,
            headers: {

            }
        }).then((res) => {
            return resolve(res);
        }).catch((err) => {
            return reject(err);
        });
    })
};

/**
 * @function 找回密码
 * @param param 
 * @returns 
 */
export const postUpdatePassword = (param) => {
    return new Promise((resolve, reject) => {
        request({
            url: '/user/update-password-sms-code',
            method: 'post',
            data: param,
            headers: {

            }
        }).then((res) => {
            return resolve(res);
        }).catch((err) => {
            return reject(err);
        });
    })
};

/**
 * @function 根据短信验证码修改签署密码
 * @param param 
 * @returns 
 */
export const postUpdateSignPassword = (param) => {
    return new Promise((resolve, reject) => {
        request({
            url: '/seal/change-pin-code-by-sms-code',
            method: 'post',
            data: param,
            headers: {

            }
        }).then((res) => {
            return resolve(res);
        }).catch((err) => {
            return reject(err);
        });
    })
};