<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <Fragments v-if="showLayouts">
        <div class="layouts_header_content">
            <div class="login_header">
                <img src="../assets/login/login_logo.png" class="logo_img" />
            </div>
            <div class="layouts_content">
                <slot></slot>
            </div>
        </div>
    </Fragments>
    <Fragments v-else>
        <div class="layouts_header_menu_content">
            <div class="layouts_header">
                <div class="layouts_header_left">
                    <img src="../assets/login/login_logo.png" class="logo_img" />
                </div>
                <div class="crumbs_box" v-if="breadcrumbs.length > 1">
                    <div class="" v-for="(item, index) in breadcrumbs" :key="item.path" @click="onGoPath(item, index)">
                        <template v-if="(index + 1) == breadcrumbs.length">
                            <span class="title" style="color: #333">{{ item.meta.title }}</span>
                        </template>
                        <template v-else>
                            <span class="title">{{ item.meta.title }}</span>
                            <span class="segmentation">/</span>
                        </template>
                    </div>
                </div>
                <div class="layouts_header_right">
                    <a-popover placement="bottom">
                        <template #content>
                            <div class="layouts_header_popover_content">
                                <div class="account_list_box">
                                    <div v-for="item in accountsList" :key="item.bodyId"
                                        :class="item.isSelect ? 'account_list select_account_list' : 'account_list'"
                                        @click="onSelectAccount(item)">
                                        <div class="account_list_left">
                                            <span>{{ item.bodyName }}</span>
                                        </div>
                                        <div class="account_list_right" v-if="item.status == 1">
                                            <img src="../assets/layouts/account_icon_01.png" alt=""
                                                class="icon account_status_icon" />
                                            <span>已认证</span>
                                            <div class="select_icon">
                                                <CheckCircleFilled class="icon" />
                                            </div>
                                        </div>
                                        <div class="account_list_right" v-else>
                                            <img src="../assets/layouts/account_icon_02.png" alt=""
                                                class="icon account_status_icon" />
                                            <span>未认证</span>
                                            <div class="select_icon">
                                                <CheckCircleFilled class="icon" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="popover_button" v-if="showPersonageBtn">
                                    <span class="a_link" @click="onAddPersonal">加入/创建个人</span>
                                </div> -->
                                <div class="popover_button">
                                    <span class="a_link" @click="onAddEnterprise">加入/创建企业</span>
                                </div>
                            </div>
                        </template>
                        <div class="account_name_status">
                            <div class="account_name">
                                <span>{{ userData.bodyName }}</span>
                            </div>
                            <template v-if="userData.status == 1">
                                <img src="../assets/layouts/account_icon_01.png" alt="" class="icon_1" />
                                <div class="account_status">
                                    <span>已认证</span>
                                </div>
                            </template>
                            <template v-else>
                                <img src="../assets/layouts/account_icon_02.png" alt="" class="icon_1" />
                                <div class="account_status">
                                    <span>未认证</span>
                                </div>
                            </template>
                            <img src="../assets//home/xl_icon.png" alt="" class="icon_2" />
                        </div>
                    </a-popover>
                    <div class="button_text" @click="onOpenDrawer(1)">
                        <span>待办中心</span>
                    </div>
                    <div class="msg_icon" @click="onOpenDrawer(2)">
                        <img src="../assets/home/xx_icon.png" alt="" class="icon" />
                        <div class="num_box" v-if="userData.unreadMessageCount">
                            <span>{{ userData.unreadMessageCount }}</span>
                        </div>
                    </div>
                    <a-popover placement="bottomRight">
                        <template #content>
                            <div class="layouts_header_popover_user">
                                <div class="user_box">
                                    <div class="user_image_left">
                                        <img src="../assets/home/user_icon.png" alt="" class="image" />
                                    </div>
                                    <div class="user_name_status">
                                        <div class="user_name">
                                            <span>{{ userData.nickname }}</span>
                                        </div>
                                        <div class="user_status" v-if="userData.status == 1">
                                            <img src="../assets/layouts/user_status_icon.png" alt="" class="icon" />
                                            <span>已实名</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="operation_box">
                                    <div class="operation_row">
                                        <div class="label">
                                            <span>手机：</span>
                                        </div>
                                        <div class="value">
                                            <span>{{ userData.phoneNumber }}</span>
                                        </div>
                                    </div>
                                    <div class="operation_row" style="cursor: pointer;">
                                        <div class="label">
                                            <UserOutlined class="icon" />
                                        </div>
                                        <div class="value">
                                            <span>用户中心</span>
                                        </div>
                                    </div>
                                    <div class="operation_row operation_row_button" @click="onLogOut">
                                        <span>退出登录</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="user_icon">
                            <img src="../assets/home/user_icon.png" alt="" class="icon" />
                        </div>
                    </a-popover>
                </div>
            </div>
            <div class="layouts_menu_content">
                <div class="layouts_menu">
                    <div :class="openMenu ? 'menu_module menu_module_open' : 'menu_module menu_module_put'">
                        <a-menu mode="inline" v-model:openKeys="openKeys" v-model:selectedKeys="selectedKeys"
                            @click="handleClick">
                            <template v-for="item_1 in menuList" :key="item_1.path">
                                <a-sub-menu :key="item_1.path" v-if="item_1?.children?.length > 0">
                                    <template #icon>
                                        <div class="sub_menu_icon">
                                            <img :src="menuIcon[item_1.path]?.selImg" alt="" class="sel_img"
                                                v-if="routePath.includes(item_1.path)" />
                                            <img :src="menuIcon[item_1.path]?.defImg" alt="" class="def_img" v-else />
                                        </div>
                                    </template>
                                    <template #title>
                                        <span class="title" v-if="openMenu">{{ item_1.meta.title }}</span>
                                    </template>
                                    <a-menu-item v-for="item_2 in item_1.children" :key="item_2.path">
                                        <div class="menu_item_two">
                                            <span class="title" v-if="openMenu">{{ item_2.meta.title }}</span>
                                        </div>
                                    </a-menu-item>
                                </a-sub-menu>
                                <a-menu-item :key="item_1.path" v-else>
                                    <div class="menu_item_row">
                                        <img :src="menuIcon[item_1.path]?.selImg" alt="" class="sel_img"
                                            v-if="routePath.includes(item_1.path)" />
                                        <img :src="menuIcon[item_1.path]?.defImg" alt="" class="def_img" v-else />
                                        <span class="title" v-if="openMenu">{{ item_1.meta.title }}</span>
                                    </div>
                                </a-menu-item>
                            </template>

                        </a-menu>
                    </div>
                    <div class="put_open_away_sidebar" @click="onOpenMenu(false)" v-if="openMenu">
                        <img src="../assets/layouts/menu_sq_icon.png" alt="" class="image" />
                        <span>收起侧边栏</span>
                    </div>
                    <div class="put_open_away_sidebar" @click="onOpenMenu(true)" v-else>
                        <img src="../assets/layouts/menu_sq_icon.png" alt="" class="image put_img" />
                    </div>
                </div>
                <div class="layouts_content">
                    <slot></slot>
                </div>
            </div>
        </div>
        <backlog-center ref="backlogCenterRef" />
        <message-center ref="messageCenterRef" />
        <friendly-reminder ref="friendlyReminderRef" />
    </Fragments>
</template>

<script>
import {
    computed,
    watch,
    ref
} from 'vue';
import {
    noLayoutsPath,
    menuPath,
    menuIcon
} from './constance.ts';
import {
    useRoute,
    useRouter
} from 'vue-router';
import { getUserInfo, getBodyList, postChangeBody } from '@/request/https/login';
import { CheckCircleFilled, UserOutlined } from '@ant-design/icons-vue';
import BacklogCenter from '@/components/backlogCenter/index.vue';
import MessageCenter from '@/components/messageCenter/index.vue';
import FriendlyReminder from '@/components/friendlyReminder/index.vue';
import { message } from 'ant-design-vue';

export default {
    components: {
        UserOutlined,
        CheckCircleFilled,
        FriendlyReminder,
        BacklogCenter,
        MessageCenter
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const showLayouts = computed(() => {
            return noLayoutsPath.includes(route.path);
        });
        const routePath = computed(() => {
            return route.path;
        });

        const openMenu = ref(true);
        const backlogCenterRef = ref(null);
        const messageCenterRef = ref(null);
        const friendlyReminderRef = ref(null);
        const openKeys = ref([]);
        const selectedKeys = ref([]);
        const userData = ref({});
        const accountsList = ref([]);
        const menuList = ref([]);
        const breadcrumbs = ref([]);
        const showPersonageBtn = ref(false);

        /**
         * @function 展开、关闭
         */
        const onOpenMenu = (v) => {
            openMenu.value = v;
        };

        /**
         * @function 打开抽屉
         */
        const onOpenDrawer = (type) => {
            if (userData.value.status == 1) {
                if (type == 1) backlogCenterRef.value.open = true;
                else messageCenterRef.value.open = true;
            } else {
                onOpenFriendlyReminder();
            }
        };

        /**
         * @function 点击菜单
         * @param enve 
         */
        const handleClick = (enve) => {
            if (userData.value.status == 1 || enve.key == '/home') router.push(enve.key);
            else onOpenFriendlyReminder();
        };

        /**
         * @function 获取用户信息
         */
        const getUserInfoData = async (type) => {
            const res = await getUserInfo({});
            if (res.code == 200) {
                userData.value = res.data;
                localStorage.setItem('user_data', JSON.stringify(res.data));
                getBodyListData(res.data);
                if (type == 2) location.reload();
            }
        };

        /**
         * @function 获取主体列表
         */
        const getBodyListData = async (data) => {
            const res = await getBodyList({});
            if (res.code == 200) {
                accountsList.value = res.data.map((item) => {
                    if (data.bodyId == item.bodyInfoId) {
                        item.isSelect = true;
                        userData.value.status = item.status;
                    } else {
                        item.isSelect = false;
                    }
                    if (item.bodyType == 1 && item.status != 1) showPersonageBtn.value = true;
                    return item;
                });
            }
        };

        /**
         * @function 退出登陆
         */
        const onLogOut = () => {
            localStorage.clear();
            router.replace('/login');
        };

        /**
         * @function 切换账号
         */
        const onSelectAccount = async (data) => {
            if (data.status == 1) {
                const res = await postChangeBody({
                    bodyInfoId: data.bodyInfoId
                });
                if (res.code == 200) {
                    getUserInfoData(2);
                    message.success('切换成功～');
                }
            }
        };

        /**
         * @function 初始化
         */
        const init = () => {
            const pathArr = location.pathname.split('/');
            if (pathArr.length > 3) {
                openKeys.value = [`/${pathArr[1]}`];
                selectedKeys.value = [`/${pathArr[1]}/${pathArr[2]}`];
            } else if (pathArr.length > 2) {
                openKeys.value = [`/${pathArr[1]}`];
                selectedKeys.value = [`/${pathArr[1]}/${pathArr[2]}`];
            } else {
                openKeys.value = [`/${pathArr[1]}`];
                selectedKeys.value = [`/${pathArr[1]}`];
            }
        };

        const handleRouterData = () => {
            breadcrumbs.value = [];
            menuList.value = [];
            router.options.routes.map((item_1) => {
                let childrenList = [];
                if (location.pathname.includes(item_1.path) && item_1.path != '/') breadcrumbs.value = [...breadcrumbs.value, item_1];
                item_1?.children?.map((item_2) => {
                    if (location.pathname.includes(item_2.path) && item_1.path != '/') breadcrumbs.value = [...breadcrumbs.value, item_2];
                    if (menuPath.includes(item_2.path)) childrenList.push(item_2);
                });
                if (menuPath.includes(item_1.path)) menuList.value = [...menuList.value, {
                    ...item_1,
                    children: childrenList
                }];
            });
        };

        /**
         * @function 加入创建企业
         */
        const onAddEnterprise = () => {
            if (userData.value.status == 1) router.push('/enterpriseCertification?applyWay=1');
            else onOpenFriendlyReminder();
        };

        /**
         * @function 加入创建个人
         */
        const onAddPersonal = () => {
            if (userData.value.status == 1) router.push('/personalCertification?applyWay=1');
            else onOpenFriendlyReminder();
        };

        const onGoPath = (item, index) => {
            if (index > 0) router.push(item.path);
        };

        const onOpenFriendlyReminder = () => {
            if (userData.value.roleType == 1) friendlyReminderRef.value.openInit({
                text: '该功能需要已认证企业使用，请先去做企业认证',
                buttonText: '企业认证',
                buttonType: 1
            });
            else friendlyReminderRef.value.openInit({
                text: '您还未进行实名认证，实名认证后即可体验更多功能',
                buttonText: '实名认证',
                buttonType: 2
            });
        };

        // 监听当前路由
        watch(() => router.currentRoute.value, (newValue) => {
            init();
            handleRouterData();
            getUserInfoData(1);
        });

        return {
            menuList,
            menuIcon,
            showLayouts,
            routePath,
            openMenu,
            backlogCenterRef,
            messageCenterRef,
            friendlyReminderRef,
            openKeys,
            selectedKeys,
            userData,
            accountsList,
            breadcrumbs,
            showPersonageBtn,
            onSelectAccount,
            onOpenMenu,
            onOpenDrawer,
            handleClick,
            onLogOut,
            onAddEnterprise,
            onAddPersonal,
            onGoPath
        }
    }
}
</script>

<style lang="scss">
.layouts_header_content {
    .login_header {
        height: 48px;
        background-color: #fff;
        border-bottom: 1px solid #EBEDF0;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .logo_img {
            width: auto;
            height: 20px;
            margin-left: 16px;
        }
    }

    .layouts_content {
        width: 100vw;
        height: calc(100vh - 48px);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
}

.layouts_header_menu_content {
    .layouts_header {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #F7F8FA;
        box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.04);
        border-radius: 0px 0px 0px 0px;
        padding: 0 16px;

        .layouts_header_left {
            width: 188px;

            .logo_img {
                width: 76px;
                height: auto;
            }
        }

        .crumbs_box {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .title {
                color: #999;
                cursor: pointer;
            }

            .segmentation {
                padding: 0 6px;
                color: #999;
            }
        }

        .layouts_header_right {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .account_name_status {
                height: 28px;
                padding: 0 16px;
                background-color: #fff;
                border-radius: 28px 28px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                cursor: pointer;

                .icon_1 {
                    width: 16px;
                    height: 16px;
                    margin-left: 16px;
                }

                .account_status {
                    padding: 0 4px;
                }

                .icon_2 {
                    width: 16px;
                    height: 16px;
                }
            }

            .button_text {
                margin-left: 24px;
                cursor: pointer;
            }

            .msg_icon {
                width: 24px;
                height: 24px;
                margin-left: 24px;
                position: relative;
                cursor: pointer;

                .icon {
                    width: 24px;
                    height: 24px;
                }

                .num_box {
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: #F5222D;
                    font-size: 12px;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: -3px;
                    right: -10px;
                }
            }

            .user_icon {
                margin-left: 32px;
                cursor: pointer;

                .icon {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }

    .layouts_menu_content {
        width: 100vw;
        height: calc(100vh - 66px);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        // overflow: hidden;

        .menu_module {
            height: calc(100vh - 102px);
        }

        .menu_module_open {
            width: 180px;

            .layouts_menu {
                width: 180px;
            }
        }

        .menu_module_put {
            width: 64px;

            .layouts_menu {
                width: 64px;
            }
        }

        .layouts_menu {
            height: 100%;
            background: #F7F8FA;
            box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 0px 0px;
            padding: 8px 4px;

            .menu_item_row,
            .sub_menu_icon {

                .def_img,
                .sel_img {
                    width: 16px;
                    height: 16px;
                }
            }

            .menu_item_row {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .title {
                    margin-left: 12px;
                }
            }

            .put_open_away_sidebar {
                height: 36px;
                border-radius: 4px 4px 4px 4px;
                border-top: 1px solid #EBEDF0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #646566;
                cursor: pointer;

                .image {
                    width: 16px;
                    height: 16px;
                    margin-right: 12px;
                }

                .put_img {
                    transform: rotate(180deg);
                }
            }
        }

        .layouts_content {
            width: calc(100% - 32px);
            padding: 16px;
            height: calc(100vh - 80px);
            overflow: auto;
        }
    }
}

.layouts_header_popover_content {
    padding: 4px 0;
    margin: -12px;

    .account_list_box {
        .account_list {
            height: 42px;
            padding: 0 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;
            cursor: pointer;
        }

        .account_list_right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-left: 32px;

            .icon {
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }

            .select_icon {
                width: 14px;
                padding: 0 2px 0 8px;

                .icon {
                    font-size: 14px;
                    color: #1677FF;
                    display: none;
                }
            }
        }

        .account_list:hover {
            background-color: #F7F9FD;
        }

        .select_account_list {
            background-color: #F7F9FD;

            .select_icon {
                .icon {
                    display: block;
                }
            }
        }
    }

    .popover_button {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #EBEDF0;
    }
}

.layouts_header_popover_user {
    width: 184px;
    min-height: 250px;
    margin: -12px;
    padding: 8px 8px 0;
    background: #FFFFFF;
    box-shadow: 0px 10px 8px 0px rgba(150, 151, 153, 0.07);
    border-radius: 4px 4px 4px 4px;

    .user_box {
        width: 152px;
        padding: 16px;
        background: #F7F8FA;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .user_image_left {
            width: 49px;
            height: 49px;

            .image {
                width: 49px;
                height: 49px;
                border-radius: 50%;
            }
        }

        .user_name_status {
            flex: 1;
            margin-left: 12px;

            .user_name {
                font-weight: 500;
                font-size: 16px;
                color: #323233;
                line-height: 22px;
            }

            .user_status {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-top: 6px;

                .icon {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                }
            }
        }
    }

    .operation_box {
        padding: 8px 8px 0;

        .operation_row {
            padding: 16px 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-bottom: 1px solid #EBEDF0;

            .label {
                color: #969799;
            }

            .icon {
                font-size: 16px;
                margin-right: 12px;
            }
        }

        .operation_row_button {
            font-size: 14px;
            color: #646566;
            justify-content: center;
            border-bottom: 0;
            cursor: pointer;
        }
    }
}
</style>