export const columns_1 = [
    {
        title: '合同名称',
        width: 292,
        dataIndex: 'docName',
        key: 'docName',
    }, {
        title: '签署方',
        dataIndex: 'sourceName',
        key: 'sourceName',
    }, {
        title: '操作',
        width: 76,
        dataIndex: 'operation',
        key: 'operation',
    }
];

export const columns_2 = [
    {
        title: '合同名称',
        width: 292,
        dataIndex: 'docName',
        key: 'docName',
    }, {
        title: '印章类型',
        dataIndex: 'sealType',
        key: 'sealType',
    }, {
        title: '操作',
        width: 76,
        dataIndex: 'operation',
        key: 'operation',
    }
];