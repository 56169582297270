import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue';
import './antd.scss';
import './global.scss';

router.beforeEach((to, from, next) => {
	if(to.meta && typeof to.meta.title === 'string') {
		document.title = to.meta.title;
	} else {
		document.title = '开企宝服务';
	}
	next();
});

const app = createApp(App);

app.use(store).use(router).use(Antd).mount('#app')
