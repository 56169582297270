<template>
    <a-config-provider :locale="zhCN">
        <layouts>
            <router-view />
        </layouts>
    </a-config-provider>
</template>
<script>
import layouts from './layouts/index.vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zn-cn');

export default {
    name: 'App',
    components: {
        layouts
    },
    setup() {
        return {
            zhCN
        }
    }
}
</script>
<style lang="scss">
body,
html {
    margin: 0;
    padding: 0;
    color: #323233;
    font-size: 14px;
    background-color: #F7F8FA;
}

img {
    margin: 0;
    padding: 0;
}

.a_link {
    color: #1677FF;
    cursor: pointer;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ellipsis_multiline {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* 显示的行数，可以根据需要修改 */
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>